body {
    font-family: "Roboto", sans-serif;
  }
  
  .sidebar-container {
    overflow-y: scroll;
    position: sticky;
    top: 0;
    height: 100vh; /* Adjust the height as needed */
    /* overflow-y: auto; Enable scrolling if the sidebar height exceeds the viewport */
  }
  
  .sidebar {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #333;
  }
  
  .sidebar-heading {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 10px;
  }
  
  .filter-group {
    margin-bottom: 20px;
  }
  
  .filter-group-heading {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .filter-divider {
    border: none;
    border-top: 1px solid #ccc;
    margin: 20px 0;
  }
  
  .filter-range-text {
    display: block;
    margin-top: 5px;
  }
  
  .brand-list {
    list-style: none;
    padding: 0;
  }
  
  .brand-item {
    margin-bottom: 5px;
  }
  
  .brand-label {
    display: block;
    font-family: "Roboto", sans-serif;
  }
  